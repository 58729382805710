import { FlippingPages } from 'flipping-pages';
import 'flipping-pages/dist/style.css';
import { useState, useEffect } from 'react';

import './App.css';

const App = () => {
    // Business Logic
    let currentLocation = 1;
    let numOfPapers = 7;
    let maxLocation = numOfPapers + 1;

    function openBook() {
        const prevBtn = document.getElementById("prev-btn");
        const nextBtn = document.getElementById("next-btn");
        const book = document.getElementById("book");
        book.style.transform = "translateX(50%)";
        prevBtn.style.transform = "translateX(-215px)";
        nextBtn.style.transform = "translateX(215px)";
        if (window.innerWidth < 1000) {
            prevBtn.style.transform = "translateX(0px)";
            prevBtn.style.transform = "translateY(150px)";
            nextBtn.style.transform = "translateX(0px)";
            nextBtn.style.transform = "translateY(150px)";
        }
    }

    function closeBook(isAtBeginning) {
        const prevBtn = document.getElementById("prev-btn");
        const nextBtn = document.getElementById("next-btn");
        const book = document.getElementById("book");
        if (isAtBeginning) {
            book.style.transform = "translateX(0%)";
        } else {
            book.style.transform = "translateX(100%)";
        }

        prevBtn.style.transform = "translateX(0px)";
        nextBtn.style.transform = "translateX(0px)";
    }

    function goNextPage() {
        const paper1 = document.getElementById("p1");
        const paper2 = document.getElementById("p2");
        const paper3 = document.getElementById("p3");
        const paper4 = document.getElementById("p4");
        const paper5 = document.getElementById("p5");
        const paper6 = document.getElementById("p6");
        const paper7 = document.getElementById("p7");
        if (currentLocation < maxLocation) {
            switch (currentLocation) {
                case 1:
                    openBook();
                    paper1.classList.add("flipped");
                    paper1.style.zIndex = 1;
                    break;
                case 2:
                    paper2.classList.add("flipped");
                    paper2.style.zIndex = 2;
                    break;
                case 3:
                    paper3.classList.add("flipped");
                    paper3.style.zIndex = 3;
                    break;
                case 4:
                    paper4.classList.add("flipped");
                    paper4.style.zIndex = 4;
                    break;
                case 5:
                    paper5.classList.add("flipped");
                    paper5.style.zIndex = 5;
                    break;
                case 6:
                    paper6.classList.add("flipped");
                    paper6.style.zIndex = 6;
                    break;
                case 7:
                    paper7.classList.add("flipped");
                    paper7.style.zIndex = 7;
                    closeBook(false);
                    break;
                default:
                    throw new Error("unkown state");
            }
            currentLocation++;
        }
    }

    function goPrevPage() {
        const paper1 = document.getElementById("p1");
        const paper2 = document.getElementById("p2");
        const paper3 = document.getElementById("p3");
        const paper4 = document.getElementById("p4");
        const paper5 = document.getElementById("p5");
        const paper6 = document.getElementById("p6");
        const paper7 = document.getElementById("p7");
        if (currentLocation > 1) {
            switch (currentLocation) {
                case 2:
                    closeBook(true);
                    paper1.classList.remove("flipped");
                    paper1.style.zIndex = 7;
                    break;
                case 3:
                    paper2.classList.remove("flipped");
                    paper2.style.zIndex = 6;
                    break;
                case 4:
                    paper3.classList.remove("flipped");
                    paper3.style.zIndex = 5;
                    break;
                case 5:
                    paper4.classList.remove("flipped");
                    paper4.style.zIndex = 4;
                    break;
                case 6:
                    paper5.classList.remove("flipped");
                    paper5.style.zIndex = 3;
                    break;
                case 7:
                    paper6.classList.remove("flipped");
                    paper6.style.zIndex = 2;
                    break;
                case 8:
                    openBook();
                    paper7.classList.remove("flipped");
                    paper7.style.zIndex = 1;
                    break;
                default:
                    throw new Error("unkown state");
            }

            currentLocation--;
        }
    }

    return (
        <div id="roadmap">
          
                <h1>ROADMAP</h1>
    
            <div className="roadmap-container" id="roadcont">

                <img className="arrow" onClick={goPrevPage} src="/assets/book/left.png" id="prev-btn" alt="prev" ></img>

                <div id="book" className="book">

                    <div id="p1" className="paper">
                        <div className="front" >
                            <div id="f1" className="front-content">
                                <img src="/assets/book/coverfront.png" alt="" />
                            </div>
                        </div>
                        <div className="back">
                            <div id="b1" className="back-content">
                                <img src="/assets/book/1.jpg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div id="p2" className="paper">
                        <div className="front">
                            <div id="f2" className="front-content">
                                <img src="/assets/book/2.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back">
                            <div id="b2" className="back-content">
                                <img src="/assets/book/3.jpg" alt="" />

                            </div>
                        </div>
                    </div>

                    <div id="p3" className="paper">
                        <div className="front">
                            <div id="f3" className="front-content">
                                <img src="/assets/book/4.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back">
                            <div id="b3" className="back-content">
                                <img src="/assets/book/5.jpg" alt="" />

                            </div>
                        </div>
                    </div>
                    <div id="p4" className="paper">
                        <div className="front">
                            <div id="f4" className="front-content">
                                <img src="/assets/book/6.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back">
                            <div id="b4" className="back-content">
                                <img src="/assets/book/7.jpg" alt="" />

                            </div>
                        </div>
                    </div>
                    <div id="p5" className="paper">
                        <div className="front">
                            <div id="f5" className="front-content">
                                <img src="/assets/book/8.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back">
                            <div id="b5" className="back-content">
                                <img src="/assets/book/9.jpg" alt="" />

                            </div>
                        </div>
                    </div>
                    <div id="p6" className="paper">
                        <div className="front">
                            <div id="f6" className="front-content">
                                <img src="/assets/book/10.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back">
                            <div id="b6" className="back-content">
                                <img src="/assets/book/11.jpg" alt="" />

                            </div>
                        </div>
                    </div>
                    <div id="p7" className="paper">
                        <div className="front">
                            <div id="f7" className="front-content">
                                <img src="/assets/book/12.jpg" alt="" />

                            </div>
                        </div>
                        <div className="back b7">
                            <div id="b7" className="back-content">
                                <img src="/assets/book/cb.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>


                <img className="arrow" src="/assets/book/right.png" alt="next" id="next-btn" onClick={goNextPage}></img>


            </div>



        </div>
    );
};

export default App;